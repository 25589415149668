


















































import draggable from "vuedraggable";
import {
    Vue,
    Component,
    Watch,
    Prop,
    Model,
    Emit,
} from "vue-property-decorator";
import {
    ElUploadInternalRawFile,
    ElUploadInternalFileDetail,
} from "element-ui/types/upload";
import { baseUrl } from '@/http/base'
@Component({
    name: "upimg",
    components: {
        draggable,
    },
})
export default class extends Vue {
    @Model("change_imgList", { type: [Array, String], required: true })
    readonly imgList!: string[] | string;
    @Emit("change_imgList")
    change_imgList(arr: string[] | string) {
		if(this.limitMax === 1){
			return arr[0]
		}else{
			return arr;
		}
    }

    @Prop({
        default: 1,
        type: Number,
    })
    readonly limitMax!: number;

    @Prop({
        default: true,
        type: Boolean,
    })
    readonly isSort!: boolean;

    @Prop({
        default: 5,
        type: Number,
    })
    readonly maxSize!: number;

    key = false;
    dialogImageUrl = "";
    sortShow = false;

    get parent_list() {
		if(typeof this.imgList === 'string') {
			return this.imgList === '' ? [] : [{ url:this.imgList }];
		}else{
			return (this.imgList as string[]).map((x) => ({ url: x }))
		}
    }
    set parent_list(arr: { url: string }[]) {
        this.change_imgList(arr.map((x) => x.url));
    }

    get api() {
        // return process.env.VUE_APP_UPLOAD;
        return baseUrl+'/file_upload/'
    }

    beforeUpload(file: ElUploadInternalRawFile) {
        const format =
            file.type === "image/jpg" ||
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/gif";
        if (!format) {
            this.$message.error("请上传jpg、jpeg、png、gif格式");
            return false;
        }
        if (file.size > 1024 * 1024 * this.maxSize) {
            this.$message.error(`图片大小不能超过${this.maxSize}M`);
            return false;
        }
        return true;
    }
    handleRemove(_: ElUploadInternalFileDetail, fileList: any[]) {
		const arr = fileList.map((x) => x?.response?.url || x.url);
        this.change_imgList(arr);
    }

    preview(file: ElUploadInternalFileDetail) {
        if (file.url === undefined) return;
        this.dialogImageUrl = file.url;
        this.key = true;
    }

    imgLoadSuccess(res: any, __: any, fileList: any[]) {

		for(let i = 0;i<fileList.length;i++){
			if(fileList[i].response){
			}else if(!fileList[i].url.includes('blob:http')){
			}else{
				return
			}
		}
        const domain = "https://images.xiyuebiaoju.com/";
        const arr = fileList.map((x) => domain +x?.response?.data || x.url);
        this.change_imgList(arr);
    }
}
