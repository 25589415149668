







//没有d.ts声明文件
// @ts-ignore
import E from "wangeditor";
import { baseUrl } from "@/http/base";
import {
    Vue,
    Component,
    Watch,
    Prop,
    Model,
    Emit,
} from "vue-property-decorator";

@Component({
    name: "editorTxt",
})
export default class extends Vue {
    @Model("change_input", { type: String, required: true })
	readonly input!: string;
    @Emit("change_input")
    change_input(str: string) {
        return str;
    }

	editor:any

    get value() {
        return this.input;
	}
    set value(val) {
        this.change_input(val);
	}
    get url() {
        return `${baseUrl}/file_upload/`;
	}

	@Watch('value')
	watch_val(){
		this.editor.txt.html(this.value)
	}

    mounted() {
		const editor:any = new E((this.$refs as any).editor);
		this.editor = editor
        editor.customConfig.onchangeTimeout = 1;
        editor.customConfig.uploadFileName = "file";
        editor.customConfig.uploadImgServer = this.url;
        editor.customConfig.pasteFilterStyle = false;
         // 视频上传
        editor.customConfig.uploadVideoServer = this.url; // 上传接口
        editor.customConfig.uploadVideoHooks = { // 上传完成处理方法
            customInsert: function (insertVideo:any, result:any) {
                insertVideo(result.data);
            }
        };
        editor.customConfig.onchange = (value: string) => {
            this.value = value;
        };
        this.editor.customConfig.uploadImgHooks = {
             customInsert: function(insertImg:any, result:any, editor:any) {
             insertImg(`https://images.xiyuebiaoju.com/${result.data}`)
            }
          };
        editor.customConfig.debug = true;
        editor.customConfig.menus = [
            "head",
            "bold",
            "fontSize",
            "fontName",
            "italic",
            "underline",
            "strikeThrough",
            "foreColor",
            "backColor",
            "link",
            "list",
            "justify",
            "quote",
            "image",
            "video",
            "table",
            "code",
            "undo",
            "redo",
        ];
        editor.customConfig.zIndex = 1;
		editor.create();
		editor.txt.html(this.value)
    }
}
