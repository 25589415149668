























































































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import {
 address, goods, user, store, order, orderDetail,
 api_order
} from "@/http/order";
import PageHeader from "@/components/pageHeader/index.vue";
import { ElForm } from "element-ui/types/form";
import Upimg from "@/components/upimg/index.vue";
import Editor from "@/components/editor/index.vue";
@Component({
  components: {
    PageHeader,
    Upimg,
    Editor,
  },
})
export default class extends Vue {
  info :orderDetail= {
    id: ""  as (string | number),
    address: {} as address,
    goods: {} as goods,
    user: {} as user,
    store: {} as store,
    order: {} as order,
    product_name: "", 
    step1: "", 
    step2: "", 
    step3: "", 
    price: "", 
    boxs:"",
    put_user:"",
    get_user:''
  };
  activeStep = 0;
  steps = [
    {
      title: "待支付", //#在柜子上(1，待取走)完成下单，还为取走的订单。
      create_time: "",
      imgUrl: "",
      status:0
    },
    {
      title: "待取走",//# 已经支付完成的，还未入库的订单
      create_time: "",
      imgUrl: "",
      status:1
    },
    {
      title: "待入库",//#已经入库，等待配送，送到清洗工厂的订单
      create_time: "",
      imgUrl:"",
      status:2
    },
    {
      /*这个状态有取走图片*/
      title: "待进场",//# 已经进入工正在清洗，等待质检的订单
      create_time: "",
      imgUrl:"",
      status:3
    },
    {
      /*这个状态有入库图片*/
      title: "清洗中",
      create_time: "",
      imgUrl:"",
      status:4
    },
    {
      /*这个状态有出库图片*/
      title: "已质检",
      create_time: "",
      imgUrl:"",
      status:5
    },
    {
      title: "待出库",//# 已经质检等待配送，送回到门店的订单
      create_time: "",
      imgUrl:"",
      status:6
    },
    {
      title: "待到店",
      create_time: "",
      imgUrl:"",
      status:7
    },
    {
      title: "待配送",
      create_time: "",
      imgUrl:"",
      status:8
    },
    {
      title: "完成",
      create_time: "",
      imgUrl:"",
      status:9
    },
   
  ];
  // steps = [
  //   {
  //     title: "买家下单",
  //     create_time: "2020.09.09 10:12",
  //     imgUrl: "",
  //   },
  //   {
  //     title: "买家存入",
  //     create_time: "",
  //     imgUrl: "",
  //   },
  //   {
  //     title: "商家取走",
  //     create_time: "",
  //     imgUrl:
  //       "20240218/4b98bfed3ae64fbabd82ab07c88ca1ec.png,20240308/151eb0763582442e907500ae4ca9be6a.jpg",
  //   },
  //   {
  //     title: "商家入库",
  //     create_time: "",
  //     imgUrl:
  //       "20240218/4b98bfed3ae64fbabd82ab07c88ca1ec.png,20240308/151eb0763582442e907500ae4ca9be6a.jpg",
  //   },
  //   {
  //     title: "正在清洗",
  //     create_time: "",
  //     imgUrl: "",
  //   },
  //   {
  //     title: "商家送回",
  //     create_time: "",
  //     imgUrl:
  //       "20240218/4b98bfed3ae64fbabd82ab07c88ca1ec.png,20240308/151eb0763582442e907500ae4ca9be6a.jpg",
  //   },
  //   {
  //     title: "完成",
  //     create_time: "",
  //     imgUrl: "",
  //   },
  // ];
  getImageUrl(cover: string) {
    if (cover && typeof cover === "string" && cover != "") {
      // 将字符串按逗号分割成数组
      const coverIds = cover.split(",");
      return coverIds.map((v) => `https://images.xiyuebiaoju.com/${v}`);
    }
    return [];
  }
  status(status: number) {
    return this.steps.find((v) => v.status == status);
  }
  // total() {
  //   return this.info.goods.price * Number(this.info.goods.number);
  // }
  //   async submit() {
  //     await (this.$refs["form"] as ElForm | undefined)?.validate();
  //     if (this.id) {
  //       await api_order.edit(this.info);
  //     } else {
  //       if (this.info.start_time == "" && this.info.fish_time == "") {
  //         delete this.info.start_time;
  //         delete this.info.fish_time;
  //       }
  //       await api_order.create(this.info);
  //     }
  //     // this.$message.success("提交成功");
  //     this.$router.go(-1);
  //   }

  async get_info() {
    this.info = await api_order.get_info(this.id as number);
    this.steps.forEach(v=>{
      if(v.status == 3){
        v.imgUrl = this.info.step1;
      }
      if(v.status == 4){
        v.imgUrl = this.info.step2;
      }
      if(v.status == 5){
        v.imgUrl = this.info.step3;
      }
    })
    // this.info.status = String(this.info.status);
    // 处理封面图
    // if (typeof this.info.cover === "string") {
    //   const domain = "https://images.xiyuebiaoju.com/"; // 替换为你的域名
    //   this.coverUrls = this.info.cover
    //     .split(",")
    //     .map((url) => domain + url.trim());
    // }
  }
  // 监听图片数据
  //   @Watch("coverUrls")
  //   setCover(val: string[]) {
  //     const domain = "https://images.xiyuebiaoju.com/";
  //     this.info.cover = val.map((url) => url.replace(domain, "")).join(", ");
  //   }
  // 监听状态
  //   @Watch("info.status")
  //   setStatus(val: number) {
  //     if (val == 1) {
  //       this.info.fish_time = null;
  //       this.info.start_time = null;
  //     }
  //   }
  get id(): undefined | number {
    return this.$route.query.id as any;
  }
  // 获取用户列表
  categoryList: any[] = [];

  //   async get_category_list() {
  //     const res = await api_orderCategory.get_list({ page_size: 200 });
  //     const list: any[] = [];
  //     // res.results.forEach((item:any)=>{
  //     //     item.value = item.city
  //     // })
  //     this.categoryList = list.concat(res.results);
  //   }

  created() {
    // this.get_category_list();
    if (this.id === undefined) return;
    this.get_info();
  }
  // 订单类型
  orderType(data: any) {
    if (data == null) return "抖音";
    switch (data.user_type) {
      case 1:
        return "微信";
      case 2:
        return "抖音";
    }
  }
}
